import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import CircularProgress from '@mui/material/CircularProgress';

import defaults from './CircularProgressDefaults';

const CircularProgressComponent = withSubtheme((props) => {
    const {
        className,
        progressCircle,
        altClass
    } = props;

    return(
        <div className={`${className} ${altClass}`}>
            <CircularProgress {...progressCircle} />
        </div>
    )
}, 'circularProgress', defaults)

export default CircularProgressComponent
