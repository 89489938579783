import * as React from "react"
import { useState, useEffect } from "react"
import { withSubtheme } from "@starberryComponentsMui"
import Button from "@mui/material/Button"

import Alert from "@myaccount/alert"
import FormTextField from "@myaccount/forms/TextField"
import FormPasswordField from "@myaccount/forms/PasswordField"
import FormSelectField from "@myaccount/forms/SelectField"
import FormHeading from "@myaccount/forms/Heading"
import FormDateField from "@myaccount/forms/FormDateField"
import FormDateTimeField from "@myaccount/forms/FormDateTimeField"
import FormDateTimeSlotField from "@myaccount/forms/FormDateTimeSlotField"
import FormFileField from "@myaccount/forms/FormFileField"
import FormSwitchField from "@myaccount/forms/FormSwitchField"
import FormRadioField from "@myaccount/forms/FormRadioField"
import FormCheckBoxField from "@myaccount/forms/FormCheckBoxField"
import FormAutoCompleteField from "@myaccount/forms/autocomplete"
import FormPhoneNumber from "@myaccount/forms/PhoneNumberField"
import FormLoqateAddressField from "@myaccount/forms/FormLoqateAddressField"
import FormMultiSelect from "@myaccount/forms/multiSelect"
import FormJsonAutoCompleteField from "@myaccount/forms/jsonAutoComplete"
import _ from "lodash";

import defaults from "./defaults"

const GlobalFormComponentSubtheme = withSubtheme(props => {
    const {
        className,
        forwardedRef,
        formFields,
        handleSubmit,
        formStateType,
        messageDialog,
        textFieldProps,
        datetimeSlotProps,
        selectFieldProps,
        radioGroupProps,
        fieldsTitle,
        formButton,
        formState,
        _data,
        data,
        customParams,
        defaultCountry,
        currencySymbol,
        fetchDataLink,
    } = props

    const btnData = data ? data : _data;
    const hideForm = props?.hideForm || false;
    const btnDisabled = formState?.loading ? true : false;
    const addtionalParams = customParams || {}

    const [basedOnRef, setBasedOnRef] = useState({})
    const [validationBasedOnRef, setValidationBasedOnRef] = useState({minPrice: '', maxPrice: ''})

    useEffect(() => {
        formFields.map(field => {
            if(field.reference) {
                setBasedOnRef({
                    ...basedOnRef,
                    [field.name]: ''
                })
            }

            if(field.validationBasedOn) {
                let validationBasedOnField = formFields.find(obj => ( obj.name === field.validationBasedOn ))
                setValidationBasedOnRef({
                    ...validationBasedOnRef,
                    [field.validationBasedOn]: getDefaultValue(validationBasedOnField) || ''
                })
            }
        })
    }, [])

    const updateValidationBasedOnRef = (obj) => {
        setValidationBasedOnRef({
            ...validationBasedOnRef,
            ...obj
        })
    }

    const getDefaultValue = (field) => {
        return _.has(formStateType, field.name) ? formStateType[field.name] : field.defaultValue;
    }

    const renderFields = (fields) => {
        return fields.map((field, index) => {
            const defaultValue = _.has(formStateType, field.name) ? formStateType[field.name] : field.defaultValue;

            switch (field.type) {
                case "text":
                case "email":
                case "number":
                    return (
                        <FormTextField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-textField"}}
                            {...textFieldProps}
                            defaultValue={defaultValue}
                            currencySymbol={currencySymbol}
                        />
                    )
                case "password":
                    return (
                        <FormPasswordField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-textField"}}
                            {...textFieldProps}
                            defaultValue={defaultValue}
                        />
                    )
                case "select":
                    return (
                        <FormSelectField
                            key={field.id}
                            {...field}
                            className="globalForm-selectField" // I'm using className as this is applied to the correct parent div of select box
                            // classes={{root: "globalForm-selectField"}}
                            {...selectFieldProps}
                            defaultValue={defaultValue}
                            setBasedOnRef={field.reference ? setBasedOnRef : ''}
                            basedOnRef={(field.reference || field.basedOn) ? basedOnRef : ''}
                            defaultKey={formStateType[field.basedOn] || field.defaultKey}
                            setValidationBasedOnRef={field.validationBasedOn ? updateValidationBasedOnRef : ''}
                            validationBasedOnRef={field.validationBasedOn ? validationBasedOnRef: ''}
                        />
                    )
                case "heading":
                    return (
                        <FormHeading
                            {...fieldsTitle}
                            key={field.id || index}
                            {...field}
                            classes={{root: "globalForm-fieldsTitle"}}
                        />
                    )
                case "date":
                    return (
                        <FormDateField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-textField"}}
                            {...textFieldProps}
                            // defaultValue={defaultValue}
                        />
                    )
                case "datetime":
                    return (
                        <FormDateTimeField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-textField"}}
                            {...textFieldProps}
                            // defaultValue={defaultValue}
                        />
                    )
                case "datetimeslot":
                    return (
                        <FormDateTimeSlotField
                            key={field.id}
                            {...field}
                            className="globalForm-textField"
                            {...textFieldProps}
                            {...datetimeSlotProps}
                            {...addtionalParams}
                            // defaultValue={defaultValue}
                        />
                    )
                case "loqateAddressSearch":
                    return (
                        <FormLoqateAddressField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-textField"}}
                            {...textFieldProps}
                            defaultValue={defaultValue}
                        />
                    )
                case "multiSelect":
                    return (
                        <FormMultiSelect
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-textField"}}
                            {...textFieldProps}
                            defaultValue={defaultValue}
                            setBasedOnRef={field.reference ? setBasedOnRef : ''}
                            basedOnRef={(field.reference || field.basedOn) ? basedOnRef : ''}
                            defaultKey={formStateType[field.basedOn] || field.defaultKey}
                            fetchDataLink={fetchDataLink}
                        />
                    )
                case "file":
                    return (
                        <FormFileField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-textField"}}
                            {...textFieldProps}
                            // defaultValue={defaultValue}
                        />
                    )
                case "switch":
                    return (
                        <FormSwitchField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-fieldsTitle"}}
                            {...textFieldProps}
                            defaultValue={defaultValue}
                        />
                    )
                case "radio":
                    return (
                        <FormRadioField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-fieldsRadio"}}
                            radioGroupProps={radioGroupProps}
                            defaultValue={defaultValue}
                        />
                    )
                case "checkbox":
                    return (
                        <FormCheckBoxField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-fieldsRadio"}}
                            radioGroupProps={radioGroupProps}
                            defaultValue={defaultValue}
                        />
                    )
                case "autocomplete":
                    return (
                        <FormAutoCompleteField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-selectField"}}
                            {...textFieldProps}
                            defaultValue={defaultValue}
                        />
                    )
                case "jsonautocomplete":
                    return (
                        <FormJsonAutoCompleteField
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-jsonAutocompleteField"}}
                            {...textFieldProps}
                            defaultValue={defaultValue}
                            fetchDataLink={fetchDataLink}
                        />
                    )
                case "tel":
                    return (
                        <FormPhoneNumber
                            key={field.id}
                            {...field}
                            classes={{root: "globalForm-selectField"}}
                            {...textFieldProps}
                            defaultValue={defaultValue}
                            defaultCountry={defaultCountry}
                        />
                    )
                case "hidden":
                    return (
                        <input {...field} />
                    )
                default:
                    return <></>
            }
        })
    }
    return(
        <form
            ref={forwardedRef}
            noValidate
            onSubmit={event => handleSubmit(event)}
            autoComplete="off"
            className={className}
        >
            <Alert type={hideForm ? 'alert' : ''} {...messageDialog} />
            {!hideForm && (
                <>
                    <div className="globalForm-fieldContainer">
                        {renderFields(formFields)}
                    </div>
                    <div className="globalForm-actionContainer">
                        <Button
                            type="submit"
                            disabled={btnDisabled}
                            {...formButton}
                            classes={{root: "globalForm-formButton"}}>
                            {formState?.loading ? (
                                <>{`Please wait...`}</>
                            ) : (
                                <>{btnData?.submitLabel ? btnData.submitLabel : "Submit"}</>
                            )}
                        </Button>
                    </div>
                </>
            )}

        </form>
    )
}, 'globalForm', defaults)

const GlobalFormComponent = React.forwardRef((props, ref) => {
    return (
        <GlobalFormComponentSubtheme {...props} forwardedRef={ref} />
    )
})

export default GlobalFormComponent
