/**
Ref: https://medium.com/front-end-weekly/html5-form-validation-in-react-65712f778196
     https://time2hack.com/form-with-react-html5-validations/
     https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

Note: default props inputRef, onChange, error HelperText can be overridden by passing it in props
*/

import React, { useState } from "react"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"

const FormRadioField = ({
    defaultValue,
    values,
    funcForValueFunc,
    funcForValue,
    onChange,
    radioGroupProps,
    name,
    label,
    ...rest
}) => {
    const [value, setValue] = useState(defaultValue || "")

    const handleChange = event => {
        setValue(event.target.value)
        // handle funcForValueFunc params
        if (
            funcForValueFunc &&
            funcForValue === event.target.value
        ) {
            funcForValueFunc()
            // handle custom onChange function
        } else if (onChange) {
            onChange(event)
        }
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                aria-label={label}
                name={name}
                value={value}
                onChange={event => {
                    handleChange(event)
                }}
                defaultValue={value}
                {...radioGroupProps}
                {...rest}
            >
                {values.map((item, i) => {
                    let optionValue = item
                    let optionLabel = item
                    if (typeof item === "object") {
                        Object.keys(item).forEach(key => {
                            optionValue = key
                            optionLabel = item[key]
                        })
                    }

                    return (
                        <FormControlLabel
                            key={i}
                            value={optionValue}
                            control={<Radio />}
                            label={optionLabel}
                        />
                    )
                })}
            </RadioGroup>
        </FormControl>
    )
}
export default FormRadioField
