import React, { useState } from 'react';
import AutoComplete from './autocomplete';

const SearchInputBox = (props) => {
    return (
        <div className="form-group autosuggest_container">
            <AutoComplete
                {...props}
            />
        </div>
    );
}

export default SearchInputBox;
