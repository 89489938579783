import { MYACCOUNT_LIGHT } from "@myaccountUrls";
import { isArray } from "lodash";

export { default as  DateWithTime} from './dateformat';
export { default as  ActionTitle} from './action';

export const getPropertyText = (value) => {

    let offerText = [];
    if (value?.tenancies && value.offers.length) {
        offerText.push(`${value.offers.length} - Offer(s)`);
    }
    if (value?.tenancies && value.tenancies.length) {
        offerText.push(`${value.tenancies.length} - Offer(s)`);
    }
    if (value?.appointments && value.appointments.length) {
        offerText.push(`${value.appointments.length} - Appointment(s)`);
    }
    offerText = offerText.join(' | ')
    return offerText;

}

export const getRentFrequencyCode = (key) => {
    switch (key) {
        case 'weekly':
            return ' PW'
        case 'monthly':
            return ' PCM'
        case 'annually':
            return ' PA'
        default:
            return ''
    }
}

export const getCurrentTab = (initialTab, tabNames = []) => {
    if (!isArray(tabNames))
        tabNames = Object.keys(tabNames)
    // looking for url hash
    if (window.location.hash) {
        let hash = window.location.hash.replace('#', '');
        // check has with available tabs
        if (tabNames.indexOf(hash) > -1) {
            return hash;
        }
    }
    return initialTab;
}

export function doProfileUpdate(user){
    // Contact id no needs to check for now in new workflow, just passing true now
    return false;
    return (!MYACCOUNT_LIGHT && (!user?.contact_id || !user.contact_id[0]))
}

export function hasSavedSearch(userobjects){
    if (userobjects && userobjects.length) {
        const searchList = userobjects.filter((item) => { return item.type === 'search' /*&& item.state*/ })
        if (searchList.length)
            return true;
    }
    return false
}

export function getTabLists(availableTabs, tabToShow) {
    let tabs = [];
    if (isArray(tabToShow)) {
        tabToShow.map((item, key) => {
            if (availableTabs[item]) {
                tabs.push(availableTabs[item])
            }
            return;
        })
    } else {
        Object.keys(tabToShow).map((key) => {
            const item = tabToShow[key];
            if (availableTabs[key]) {
                tabs.push({...availableTabs[key], ...item})
            }
            return
        })
    }

    return tabs;
}

// Create a new array with only the id and name properties, we don't need the rest for the menus
export function collectMenuTabs(tabLists) {
    if (!tabLists) return

    if (isArray(tabLists))
        return tabLists.map(({content, ...keepData}) => keepData)

    return Object.keys(tabLists).map((key) => {
        const item = tabLists[key];
        const {content, ...keepData} = item;
        return keepData;
    })
}

export const capitalizeFirstLetter = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const trackEvents = (params) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: params.event,
      eventId: params.eventId,
    })
}
