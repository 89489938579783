import React from "react";
import { forwardRef } from 'react';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment'

const CustomPhoneNumber = (props, ref) => {
    const {
        hasAdornment,
        inputProps,
        hasError,
    } = props

    return(
        <TextField
            {...props}
            inputRef={ref}
            // onChange={(event) => {
            //     doValidate(event)
            // }}
            {...inputProps}
            // error={hasError}
            // helperText={hasError ? helperText : ""}
            // InputProps={hasAdornment && {
            //     startAdornment: <InputAdornment position="start">{hasAdornment}</InputAdornment>,
            // }}
        />
    )
}

export default forwardRef(CustomPhoneNumber)
