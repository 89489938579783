import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    Configure,
    connectSearchBox,
} from 'react-instantsearch-dom';
import AutoComplete from './autocomplete';

const VirtalSearchBox = connectSearchBox(() => null);

const searchClient = algoliasearch(
	`${process.env.GATSBY_ALGOLIA_APPLICATION_ID}`,
	`${process.env.GATSBY_ALGOLIA_SEARCH_API_KEY}`
);

const SearchInputBox = (props) => {

    const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
    const [query, setQery] = useState('');

    const onSuggestionSelected = (_, { suggestion }) => {
        setQery(suggestion.area);
    };

    const onSuggestionCleared = () => {
        setQery('')
    };

    return (
        <div className="form-group autosuggest_container">
            <InstantSearch indexName={index_name} searchClient={searchClient}>
                <Configure hitsPerPage={5} distinct={true} />
                <AutoComplete
                    onSuggestionSelected={onSuggestionSelected}
                    onSuggestionCleared={onSuggestionCleared}
                    {...props}
                />
            </InstantSearch>

            <InstantSearch indexName={index_name} searchClient={searchClient}>
                <VirtalSearchBox defaultRefinement={query} />
            </InstantSearch>
        </div>
    );
}

export default SearchInputBox;