export default {
    "props": {
        "progressCircle": {
            "color": "primary",
            // "thickness": 2,
            // "disableShrink": true, // if any performance issues enable this prop, it removes the shring animation from circle https://github.com/mui-org/material-ui/issues/10327
        },
    },
    "sx": (theme) => ({
        "display": "flex",
        "justifyContent": "center",
        "padding": theme.spacing(4,2),
        [theme.breakpoints.up('md')]: {
            "padding": theme.spacing(8,2),
        },
        "&.padding-b-20": {
            "padding": theme.spacing(0, 0, 4, 0),
            [theme.breakpoints.up('md')]: {
                "padding": theme.spacing(0, 0, 4, 0),
            },
        }
    }),
}
