import React from "react"
import { ViewingDate } from "@myaccount/helper/dateformat"
import { CURRENCY_CODE } from "@myaccountUrls";

export const getRoleType = ( data ) => {
    let role = 'applicant';
    if (data.is_owner)
        role =  'owner';
    return data?.role || role;
}

export const propertyAddress = data => {

    if (data?.display_address)
        return `for ${data?.display_address}`;

    if (data?.property_address) {
        const address = data.property_address;
        if (address) {
            let display_address = [
                address.street_name,
                address.town,
                // address.postcode
            ]
            display_address = display_address.filter(n => n) // remove empty https://stackoverflow.com/questions/281264/remove-empty-elements-from-an-array-in-javascript
            display_address = display_address.join(", ")
            return `for ${display_address}`
        }
    }
    return "";
}

export const propertyShortAddress = address => {
    let display_address = [
        address.buildingNumber,
        address.line1,
        address.postcode
    ]
    display_address = display_address.filter(n => n) // remove empty https://stackoverflow.com/questions/281264/remove-empty-elements-from-an-array-in-javascript
    return display_address.join(", ")
}

export const formatPrice = price => {
    if (!price)
        return '';
    return new Intl.NumberFormat("en", {
        style: "currency",
        currency: CURRENCY_CODE || "GBP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(price)
}

export const ActionTitle = ({ data, ...rest }) => {
    const action = data.action.trim()

    const display_address = propertyAddress(data)

    let title = action
    let start_date;

    switch (action) {

        case "pending_valuation":
            title = (
                <>
                    {`Upcoming Valuation ${display_address} on `}{" "}
                    <ViewingDate timestamp={data.value} />
                </>
            )
            break

        case "task": //move to tenancies file
            start_date = '';
            if (data?.task_start_date)
                start_date = ` - ${ data.task_start_date}`;
            title = `Task - ${data.message} ${start_date}`
            break
        case "transaction": //move to tenancies file
            title = `${data.category}(category) - ${data.description}(description) - ${data.net_amount}(amount) - ${data.type}(type) - ${data.status}`
            break

        case "Market Appraisal":
            title = (
                <>
                    {`Market Appraisal `} {" on "}
                    <ViewingDate timestamp={data.value} />
                </>
            )
            break
        default:
            title = action
    }

    // title = data.id

    return <>{title}</>
}

export const getUserRole = (property = {}, data = {}) => {
    if (data?.userRole)
        return data?.userRole;
    if (property) {
        if (property?.search_type === 'lettings')
            return 'renting';
        else if (property?.search_type === 'sales')
            return 'buying';
        else
            return null;
    }

    return null;
}


// Probably better to not export a default, as there are many choices, and
// this file isn't called ActionTitle.jsx
//export default ActionTitle;
