// Ref: https://medium.com/front-end-weekly/html5-form-validation-in-react-65712f778196
//      https://time2hack.com/form-with-react-html5-validations/
//      https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation
import React from 'react';
import Typography from "@mui/material/Typography"

const FormHeading = ( props ) => {
    const { title, ...rest } = props;
    return (
        <Typography {...rest}>
            {title}
        </Typography>
    )
}
export default FormHeading;
