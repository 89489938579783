import React, { useState, useMemo } from "react";
import AutoSuggest from 'react-autosuggest';
import _ from "lodash"
import axios from 'axios';
import { TextField, Paper } from '@material-ui/core';
import { getAreas } from "@myaccountServices/store/api"

const API_URL = process.env.REACT_APP_API_URL || process.env.GATSBY_API_URL

const removeExtraStr = (str) => {
    if (str) {
        return str.replace(/ *\([^)]*\) */g, "")
    }
}
const JsonAutoComplete = (props) => {
    const [value, setValue] = useState(props.currentRefinement || props.defaultValue || '')
    const [areas, setAreas] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [selectedValue, setSelectedValue] = useState(props.currentRefinement || props.defaultValue || '')
    const dropDownLabel = props.dropDownLabel ?? 'displayName';
    const dropDownValue = props.dropDownValue ?? 'alias';
    const selectedLabel = props.selectedLabel ?? 'alias';

    const {
        id,
        name,
        classes,
        fetchDataLink,
        fullPath
    } = props;

    const placeholder = _.get(props, 'placeholder', 'Location')

    useMemo(() => {
        async function getDatas() {
            if (fetchDataLink) {
                if(fullPath) {
                    const { data } = await axios.get(fetchDataLink);
                    setAreas(data);
                } else {
                    const { data } = await axios.get(`${API_URL}/${fetchDataLink}`);
                    setAreas(data);
                }
            } else {
                const { data } = await getAreas();
                setAreas(data);
            }
        }

        getDatas()

    }, [fetchDataLink]);

    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var exactMatch = [];
        var randomMatch = [];
        var suggestList = [];
        exactMatch = inputLength === 0 ? [] : areas.filter(area =>
            // area.displayName.toLowerCase().slice(0, inputLength) === inputValue
            area[dropDownValue].trim().toLowerCase() === inputValue
        );

        randomMatch = inputLength === 0 ? [] : areas.filter(area =>
            // area.displayName.toLowerCase().includes(inputValue.toLowerCase())
            area[dropDownValue].trim().toLowerCase().includes(inputValue)
        );

        exactMatch.forEach((val) => {
            suggestList.push(val)
        })
        randomMatch.forEach((val) => {
            suggestList.push(val)
        })
        // return _.uniqBy(suggestList, 'displayName')
        return _.uniqBy(suggestList, dropDownValue)
    };

    const onSuggestionsFetchRequested = ({value}) => {
        setSuggestions(getSuggestions(value));
    }

    const onSuggestionSelected = (e, { suggestion }) => {
        if (suggestion) {
            setValue(suggestion[dropDownLabel]);
            // setValue(suggestion.alias);
            setSelectedValue(removeExtraStr(suggestion[selectedLabel]));
            if (props?.onChange) {
                props.onChange(suggestion[dropDownLabel]);
            }
        }
    }

    const getSuggestionValue = suggestion => suggestion[dropDownLabel];
    // const getSuggestionValue = suggestion => suggestion.alias;

    const renderInputComponent = passInputProps => (
        <TextField
            label={placeholder}
            inputProps={passInputProps}
            classes={classes}
            size="small"
        />
    )

    const renderSuggestion = (suggestion) => {
        return <span>{suggestion[dropDownLabel]}</span>;
    }

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    }

    const onChange = (_, { newValue }) => {
        if (!newValue) {
            onSuggestionsClearRequested();
        }
        setValue(newValue);
        setSelectedValue(removeExtraStr(newValue));

        if (props?.onChange) {
            props.onChange(newValue);
        }

        console.log("newValue => ", newValue)
    };

    const inputProps = {
        onChange: onChange,
        // name: name,
        value,
    };

    return (
        <div className="form-group autosuggest_container">
            <AutoSuggest
                id={id}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                theme={{
                    container: "suggestionsContainer",
                    suggestionsContainerOpen: "suggestionsContainerOpen",
                    suggestionsList: "suggestionsList",
                    suggestion: "suggestion",
                }}
                renderInputComponent={renderInputComponent}
                renderSuggestionsContainer={options => (
                    <Paper {...options.containerProps} elevation={3}>
                        {options.children}
                    </Paper>
                )}
            />

            <input type="hidden" name={name} value={selectedValue} />
        </div>
    )
}

export default JsonAutoComplete
