import React, { useState } from 'react';
import { Highlight, connectAutoComplete } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

const AutoComplete = (props) => {
    const [value, setValue] = useState(props.currentRefinement || props.defaultValue || '')

    const onChange = (_, { newValue }) => {
        if (!newValue) {
            props.onSuggestionCleared();
        }
        setValue(newValue);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        props.refine(value);
    };

    const onSuggestionsClearRequested = () => {
      props.refine();
    };

    const getSuggestionValue = (hit) => {
      return hit.area;
    }

    const renderSuggestion = (hit) => {
        return <Highlight attribute="area" hit={hit} tagName="mark" />;
    }

    const {
        hits,
        onSuggestionSelected,
        classes,
        id,
        name,
        // ...rest
    } = props;

    const inputProps = {
        onChange: onChange,
        name: name,
        value,
    };

    const renderInputComponent = passInputProps => (
        <TextField
            label="Location"
            inputProps={passInputProps}
            classes={classes}
        />
    );

    return (
        <AutoSuggest
            id={id}
            suggestions={hits}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            theme={{
                container: "suggestionsContainer",
                suggestionsContainerOpen: "suggestionsContainerOpen",
                suggestionsList: "suggestionsList",
                suggestion: "suggestion",
            }}
            renderInputComponent={renderInputComponent}
            renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} elevation={3}>
                    {options.children}
                </Paper>
            )}
        />
    );
  }

  export default connectAutoComplete(AutoComplete);
