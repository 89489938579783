/**
Ref: https://medium.com/front-end-weekly/html5-form-validation-in-react-65712f778196
     https://time2hack.com/form-with-react-html5-validations/
     https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

Note: default props inputRef, onChange, error HelperText can be overridden by passing it in props
*/

import React, { useRef, useState } from "react"
import TextField from "@mui/material/TextField"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from '@mui/lab/DatePicker'

const DateTextField = props => {
    const { ref, doValidate, hasError, helperText, setOpen, ...rest } = props
    return (
        <TextField
            onClick={() => { setOpen(true)}}
            inputRef={ref}
            onChange={() => {
                doValidate()
            }}
            error={hasError}
            helperText={hasError ? helperText : ""}
            {...rest}
        />
    )
}

const FormDateField = props => {
    const [selectedDate, handleDateChange] = React.useState(() => {
        return props?.inputProps?.defaultValue || null;
    })

    const hasHelperText = props?.helperText
    const [hasError, setHasError] = useState(false)
    const [helperText, setHelperText] = useState(props?.helperText)
    const [open, setOpen] = useState(false)
    const ref = useRef(null)
    const doValidate = () => {
        const valid = ref.current.checkValidity()
        const invalidMsg = ref.current.validationMessage
        if (!hasHelperText && helperText !== invalidMsg && invalidMsg) {
            setHelperText(invalidMsg)
        }
        if (hasError === valid) setHasError(!valid)
    }

    let showPastDate = {
        minDate:new Date()
    }
    if (props?.showPastDate)
        showPastDate = {}

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                {...props}
                renderInput={rest => {
                    let inputRest = {...rest}
                    // remove type from props
                    let { type, ...additionalProps} = props
                    inputRest.inputProps.placeholder = inputRest.label;
                    let error = props.error;
                    if (inputRest?.inputProps?.value)
                        error = false;
                    return(
                        <DateTextField
                            ref={ref}
                            doValidate={doValidate}
                            hasError={hasError}
                            helperText={hasError ? helperText : ""}
                            setOpen={setOpen}
                            {...additionalProps}
                            {...inputRest}
                            error={error}
                            helperText={props.helperText}
                        />
                    )
                }}
                inputFormat={`yyyy-MM-dd`}
                label={props?.label}
                value={selectedDate}
                onChange={props?.handleOnChange || handleDateChange}
                onClose={() => {setOpen(false)}}
                open={open}
                {...showPastDate}
                disableMaskedInput={true} // if it true type set as tel instead of text
                classes={{
                    root: "calendar-dateTimeMui",
                }}
                desktopModeMediaQuery="@media (min-width: 1300px)"
            />
        </LocalizationProvider>
    )
}
export default FormDateField
