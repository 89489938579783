export const getUserRole = (property = {}, data = {}) => {
    if (data?.applicant_type) {
        switch (data?.applicant_type) {
            case 'buying':
                return 'Buyer'
            case 'renting':
                return 'Tenant'       
            default:
                break;
        }
    }

    if (data?.is_owner) {
        if (data.owner_type === 'sales')
            return 'Seller'
        else if (data.owner_type === 'lettings')
            return 'Landlord'
    }

    if (!data?.is_owner) {
        if (data.owner_type === 'sales')
            return 'Buyer'
        else if (data.owner_type === 'lettings')
            return 'Tenant'
    }

    if (property && property?.search_type) {
        if (property?.search_type === 'lettings' && data.is_owner)
            return 'Landlord';
        if (property?.search_type === 'lettings' && !data.is_owner)
            return 'Tenant';
        else if (property?.search_type === 'sales' && data.is_owner)
            return 'Seller';
        else if (property?.search_type === 'sales' && !data.is_owner)
            return 'Buyer';
        else
            return null;
    }

    if (data?.is_applicant)
        return 'Applicant'

    return null;
}

export const trackEvents = (params) => {
    console.log("trackEvents => ", params);
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({...params})
}

// Sign in
export const trackSignIn = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'myAccountSigninForm',
        ...props
    })
}

// Sign up
export const trackSignUp = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'myAccountSignupForm',
        ...props
    })
}

export const trackBtnClick = (props = {}) => {
    trackEvents({
        event: 'buttonClick',
        eventId: 'button',
        ...props
    })
}

// For persona update
export const trackPersonaUpdate = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Register your property preferences',
        // eventLabel: values.profile_persona,
        ...props
    })
}

// For vauation submit
export const trackValuation = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Value my property',
        ...props
    })
}

// For preference update
export const trackPropertyPref = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Property Preference',
        ...props
    })
}

// For Save search
export const trackSaveSearch = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Save Search',
        ...props
    })
}

// For Save property
export const trackSaveProperty = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Save Property',
        ...props
    })
}

// For Save property
export const trackSaveSearchBtn = (props = {}) => {
    trackEvents({
        event: 'buttonClick',
        eventId: 'Save Property',
        ...props
    })
}

/** Actions Start */
// Feedback
export const trackFeedback = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Post Feedback',
        ...props
    })
}

// Viewing
export const trackViewing = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Book a viewing - My Account',
        ...props
    })
}

export const trackViewingStatus = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Viewing - Status',
        ...props
    })
}

export const trackOfferStatus = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Make an Offer',
        ...props
    })
}

// Property contact
export const trackPropertyContact = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Contact Us',
        ...props
    })
}

// people contact
export const trackNegotiatorContact = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'People Contact Us',
        ...props
    })
}

export const trackCreateTenancy = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Tenancy Offer',
        ...props
    })
}

export const trackTenancyStatus = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Tenancy Offer',
        ...props
    })
}

export const trackMakeAnOffer = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Make an Offer',
        ...props
    })
}

export const trackNewDocument = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Document uploaded',
        ...props
    })
}

export const trackDocumentDownload = (props = {}) => {
    trackEvents({
        event: 'buttonClick',
        eventId: 'Document Download',
        ...props
    })
}

export const trackReportFault = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Report a Fault',
        ...props
    })
}

export const trackReportFaultIframe = (props = {}) => {
    trackEvents({
        event: 'buttonClick',
        eventId: 'Report a Fault',
        ...props
    })
}

export const trackUtilities = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Utilities',
        ...props
    })
}

export const trackProfile = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Profile',
        ...props
    })
}

export const trackWorksOrder = (props = {}) => {
    trackEvents({
        event: 'formSubmit',
        eventId: 'Report a Fault',
        ...props
    })
}
