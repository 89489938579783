/**
Ref: https://medium.com/front-end-weekly/html5-form-validation-in-react-65712f778196
     https://time2hack.com/form-with-react-html5-validations/
     https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

Note: default props inputRef, onChange, error HelperText can be overridden by passing it in props
*/

import React from "react";
import { withSubtheme } from "@starberryComponentsMui"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import defaults from './defaults';

const FormSwitchField = withSubtheme(props => {
    const {
        className,
        name,
        label,
        id,
        defaultValue,
        formGroupProps,
        switchProps,
    } = props

    return (
        <FormGroup {...formGroupProps} classes={{root: className}}>
            <FormControlLabel
                control={
                    <Switch
                        defaultChecked={defaultValue}
                        name={name}
                        id={id}
                        {...switchProps}
                    />
                }
                label={label}
            />
        </FormGroup>
    )
}, "formSwitchField", defaults)

export default FormSwitchField
