export default {
    "props": {
        "formGroupProps": {
            "row": true,
        },
        "switchProps": {
            "color": "primary",
        },
    },
    "sx": (theme) => ({
        "marginBottom": theme.spacing(3),
    }),
}
