import React, { Component, useState, useEffect } from "react"
import { isString, isEmpty, get } from 'lodash';
import TextField from "@mui/material/TextField"
import { indexOf } from "lodash"
import Select from "react-select"
import { getAreas } from "@myaccountServices/store/api"
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || process.env.GATSBY_API_URL

const MultiSelect = (props) => {

    const {
        defaultValue,
        name,
        basedOn,
        basedOnRef,
        setBasedOnRef,
        reference,
        defaultKey,
        fullPath,
        fetchDataLink,
        dropDownValue,
        dropDownLabel
    } = props

    const placeholder = props?.placeholder || 'Start typing your area';
    const selectParams = props?.params || {}
    const [value, setValue] = useState(defaultValue || "")
    const [basedOnValue, setBasedOnValue] = useState(defaultKey || '')
    const defaultDropDownValue = dropDownValue || 'value';
    const defaultDropDownLabel = dropDownLabel|| 'label';

    const getValue = (item, value) => {
        return get(item, value, '')
    }
    const [localState, setLocalState] = useState({
        options: [],
        isLoading: false,
        defaultValue: [],
        selectedvalue: [],
    })
    let defaultValueObjects = []
    if (defaultValue) {
        if (isString(defaultValue))
            defaultValueObjects = defaultValue.split(', ');
        else
            defaultValueObjects = defaultValue
    }

    useEffect(() => {
        if ((reference || basedOn) && basedOnRef[basedOn]) {
            setBasedOnValue(basedOnRef[basedOn])
            if (reference)
                setValue(basedOnRef[basedOn])
        }
    }, [basedOnRef]);

    useEffect(() => {
        setLocalState({
            ...localState,
            isLoading: true,
        })
        if (isEmpty(props?.values)) {

            const doAPICall = async() => {
                let apiRes = '';
                if (fetchDataLink) {
                    if(fullPath) {
                        apiRes = await axios.get(fetchDataLink);
                    } else {
                        apiRes = await axios.get(`${API_URL}/${fetchDataLink}`);
                    }
                } else {
                    apiRes = await getAreas()
                }

                if (apiRes) {
                    if (apiRes.data.length) {
                        let selectedVal = apiRes.data.filter(item => {
                            if (indexOf(defaultValueObjects, getValue(item, defaultDropDownLabel).toLowerCase()) >= 0) {
                                return item
                            }
                        })
                        setLocalState({
                            ...apiRes,
                            options: apiRes.data,
                            isLoading: false,
                            defaultValue: selectedVal,
                            selectedvalue: selectedVal
                        })
                    }
                }
            }

            doAPICall();

        } else {
            let values = [];
            if(Array.isArray(props?.values)) {
                values = props?.values
            } else {
                if(basedOn && basedOnRef[basedOn])
                    values = props?.values[basedOnValue]
                else
                values = props?.values[defaultKey]
            }
            let selectedVal = values.filter(item => {
                // if (indexOf(defaultValueObjects, item.label) >= 0) {
                if (indexOf(defaultValueObjects, getValue(item, defaultDropDownValue)) >= 0) {
                    return item
                }
            })
            setLocalState({
                ...localState,
                options: values,
                isLoading: false,
                defaultValue: selectedVal,
                selectedvalue: selectedVal
            })
        }
    }, [basedOnValue])

    const onChange = values => {
        setLocalState({
            ...localState,
            selectedvalue: values
        })

        if (reference) {
            if(isEmpty(values)){
                setBasedOnRef({[name]: defaultKey})
            } else {
                setBasedOnRef({[name]: values})
            }
        }

        setValue(values)
    }

    const getAreaNameData = () => {
        let data = ''
        localState.selectedvalue.map((item) => {
            if (!data)
                data += getValue(item, defaultDropDownLabel)
            else
                data += `, ${getValue(item, defaultDropDownLabel)}`
        })

        return data
    }

    const getAreaIDsData = () => {
        let data = ''
        localState.selectedvalue.map((item) => {
            if (!data)
                data += getValue(item, defaultDropDownValue)
            else
                data += `, ${getValue(item, defaultDropDownValue)}`
        })

        return data
    }

    return (
        <>
            <Select
                value={localState.selectedvalue}
                className="multi-select-input globalForm-selectField"
                classNamePrefix="multi-select"
                options={localState.options}
                isMulti
                isLoading={localState.isLoading}
                onChange={onChange}
                defaultValue={localState.defaultValue}
                placeholder={placeholder}
                {...selectParams}
                // clearable={true}
                // allowSelectAll={true}
                // closeMenuOnSelect={false}
                // hideSelectedOptions={false}
            />
            <div style={{display: 'none'}}>
                <TextField name={name} type="hidden" value={getAreaNameData()} />
                <TextField name={`${name}_ids`} type="hidden" value={getAreaIDsData()} />
            </div>
        </>
    )
}

export default MultiSelect
