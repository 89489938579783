/**
Ref: https://medium.com/front-end-weekly/html5-form-validation-in-react-65712f778196
     https://time2hack.com/form-with-react-html5-validations/
     https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

Note: default props inputRef, onChange, error HelperText can be overridden by passing it in props
*/

import React from "react"
import AddressSearch from "react-loqate"
import TextField from "@mui/material/TextField"
import styles from "./styles.css"

const AddressSearchInput = props => {

    return (
        <TextField
            // placeholder={"Start typing your address or postcode"}
            autocomplete="chrome-off"
            id="postalCode"
            name="postalCode"
            label="Start typing your address or postcode"
            {...props}
        />
    )
}

const FormLoqateAddressField = ({ defaultValue, name, ...rest }) => {

    const apiKey = process.env.GATSBY_LOQATE_API_KEY || '';

    const [address, setAddress] = React.useState(() => {
        return {
            address: defaultValue || ``,
            postcode: ``,
            buildingNumber: ``,
            buildingName: ``,
            line1: ``,
            line2: ``,
            line3: ``,
            line4: ``,
            countryId: ``,
            type: ``,
            deparmant: ``,
        }
    })

    const handleChange = addr => {
        let address = addr.Label.replace(/\n/g, ", ");
        setAddress({
            address: address,
            postcode: addr.PostalCode,
            buildingName: addr.BuildingName,
            buildingNumber: addr.BuildingNumber,
            line1: addr.Line1,
            line2: addr.Line2,
            line3: addr.Line3,
            line4: addr.Line4,
            countryId: addr.CountryIso2,
            type: addr.Type,
            deparmant: addr.Department,
        })
        setTimeout(function () {
            document.getElementById("postalCode").value = address
            // document.getElementById("postcode").value = address
        }, 50)
    }

    return (
        <>
            <AddressSearch
                {...rest}
                locale="en_GB"
                value={address.address}
                apiKey={apiKey}
                countries={["GB"]}
                components={{ Input: AddressSearchInput }}
                className="address-search-box"
                listItemClassname="loqate-option"
                listClassname="loqate-options"
                onSelect={handleChange}
                id="loqate-address-search"
                inputClassname={`globalForm-textField loqate-address-search-input`}
                onChange={handleChange}
                inline
            />
            <div style={{ display: "none" }}>
                <TextField id="postcode" name={name || 'postcode'} type="hidden" value={address.postcode} />
                <TextField id="propertyAddress" name="property_address" type="hidden" value={address.address} />
                <TextField id="buildingNumber" name="buildingNumber" type="hidden" value={address.buildingNumber} />
                <TextField id="buildingName" name="buildingName" type="hidden" value={address.buildingName} />
                <TextField id="line1" name="line1" type="hidden" value={address.line1} />
                <TextField id="line2" name="line2" type="hidden" value={address.line2} />
                <TextField id="line3" name="line3" type="hidden" value={address.line3} />
                <TextField id="line4" name="line4" type="hidden" value={address.line4} />
                <TextField id="countryId" name="countryId" type="hidden" value={address.countryId} />
                <TextField id="type" name="type" type="hidden" value={address.type} />
                <TextField id="deparmant" name="deparmant" type="hidden" value={address.deparmant} />
            </div>
        </>
    )
}
export default FormLoqateAddressField
