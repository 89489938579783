/**
Ref: https://medium.com/front-end-weekly/html5-form-validation-in-react-65712f778196
     https://time2hack.com/form-with-react-html5-validations/
     https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

Note: default props inputRef, onChange, error HelperText can be overridden by passing it in props
*/

import React, { useRef, useState } from "react"
import TextField from "@mui/material/TextField"

const FormFileField = props => {
    const hasHelperText = props?.helperText
    const [hasError, setHasError] = useState(false)
    const [helperText, setHelperText] = useState(props?.helperText)
    const ref = useRef(null)
    const doValidate = () => {
        const valid = ref.current.checkValidity()
        const invalidMsg = ref.current.validationMessage
        if (!hasHelperText && helperText !== invalidMsg && invalidMsg) {
            setHelperText(invalidMsg)
        }
        if (hasError === valid) {
            setHasError(!valid)
        } else {
            setHasError(false)
        }
    }
    return (
        <TextField
            inputRef={ref}
            onChange={() => {
                doValidate()
            }}
            error={hasError}
            helperText={hasError ? helperText : ""}
            {...props}
            label="Select File"
            InputLabelProps={{
                shrink: true,
            }}
        />
    )
}
export default FormFileField
