/**
Ref: https://medium.com/front-end-weekly/html5-form-validation-in-react-65712f778196
     https://time2hack.com/form-with-react-html5-validations/
     https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

Note: default props inputRef, onChange, error HelperText can be overridden by passing it in props
*/

import React, { useRef, useState } from "react"
import TextField from "@mui/material/TextField"
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const FormTextField = props => {
    let inputProps = { ...props }
    const hasHelperText = inputProps?.helperText
    const [hasError, setHasError] = useState(() => {
        return inputProps?.error || false
    })
    const [helperText, setHelperText] = useState(() => {
        return inputProps?.helperText || ""
    })
    const [showPassword, setShowPassword] = useState(() => {
        return false
    })
    const ref = useRef(null)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const doValidate = (event) => {
        const valid = event.target.checkValidity()
        const invalidMsg = event.target.validationMessage
        // To override default validation error message using helperText props
        if (!hasHelperText && helperText !== invalidMsg && invalidMsg) {
            setHelperText(invalidMsg)
        }
        if (!valid) {
            setHasError(true)
        } else {
            setHasError(false)
        }
    }
    return (
        <>
            <TextField
                inputRef={ref}
                onChange={(event) => {
                    doValidate(event)
                }}
                {...inputProps}
                error={hasError}
                helperText={hasError ? helperText : ""}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    )
}
export default FormTextField
