/**
Ref: https://medium.com/front-end-weekly/html5-form-validation-in-react-65712f778196
     https://time2hack.com/form-with-react-html5-validations/
     https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

Note: default props inputRef, onChange, error HelperText can be overridden by passing it in props
*/

import React, { useState } from "react"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FormRadioField = ({
    defaultValue,
    values,
    funcForValueFunc,
    funcForValue,
    onChange,
    radioGroupProps,
    name,
    label,
    ...rest
}) => {
    const [value, setValue] = useState(defaultValue || "")
    const [value1, setValue1] = useState(false)

    const handleChange = event => {
        setValue1(event.target.checked)
    }

    return (
        <FormGroup row>
            {values.map((item, i) => {
                // let optionValue = item
                let optionLabel = item
                if (typeof item === "object") {
                    Object.keys(item).forEach(key => {
                        // optionValue = key
                        optionLabel = item[key]
                    })
                }

                return (
                    <FormControlLabel
                    control={<Checkbox checked={value1} onChange={handleChange} name="checkedA" value={value} />}
                    label={optionLabel}
                    {...rest}
                    />
                )
            })}
        </FormGroup>
    )
}
export default FormRadioField
