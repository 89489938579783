import React, { useRef, useState } from "react"
import PhoneInput, { formatPhoneNumberIntl, isPossiblePhoneNumber } from 'react-phone-number-input';

import CustomPhoneNumber from './MuiPhoneNumber'

const PhoneNumberField = props => {
    let inputProps = { ...props }
    const [value, setValue] = useState(formatPhoneNumberIntl(props.defaultValue) || ``)
    const [hasError, setHasError] = useState(() => {
        return inputProps?.error || false
    })
    const helperText = inputProps?.helperText || "";
    const ref = useRef(null)

    const doValidate = (newValue) => {
        let valid = false;
        if(newValue)
            valid = isPossiblePhoneNumber(newValue);
        if (!valid) {
            setHasError(true)
        } else {
            setHasError(false)
        }
        setValue(newValue);
        if (props?.handleOnChange)
            props.handleOnChange(newValue)
    }

    return (
        <div className="form-group phonenumber_container">
            <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry={props.defaultCountry}
                placeholder="Enter phone number"
                value={value}
                inputRef={ref}
                onChange={(newValue) => {
                    doValidate(newValue)
                }}
                {...inputProps}
                error={hasError}
                helperText={hasError ? helperText : ""}
                inputComponent={CustomPhoneNumber}
            />
        </div>
    )
}
export default PhoneNumberField
