/**
Ref: https://medium.com/front-end-weekly/html5-form-validation-in-react-65712f778196
     https://time2hack.com/form-with-react-html5-validations/
     https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

Note: default props inputRef, onChange, error HelperText can be overridden by passing it in props
*/

import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import { get, isEmpty } from "lodash";
import CustomPhoneNumber from './MuiDateTimeSlotField'
import { FormOptionStoreProvider, useFormState } from "@myaccountServices";
import { getDateFormat, getTime, datePickerFormat } from '@myaccount/helper/dateformat';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import DatePicker from "react-datepicker";
import { setMinutes, setHours, subDays, addDays } from "date-fns"

import "react-datepicker/dist/react-datepicker.css";

const FormDateField = props => {

    const showBtnSuitableTime = props?.showBtnSuitableTime || false
    const { state:state, services:formServices } = useFormState();

    const [startDate, setStartDate] = useState(
        (props.defaultValue ? new Date(props.defaultValue) : '') || ``
    );

    const [selectedItems, setSelectedItems] = useState({
        negotiator_id: /*state?.negotiator_id ||*/ ``,
        start_time: state?.start_time || ``,
        end_time: state?.end_time || ``
    });

    const [slots, setSlots] = React.useState(() => {
        return []
    })

    if (state.loading) {
        return <Typography variant="body2">Fetching slots...<br /><br /></Typography>
    }

    const timeIntervals = 15; // get(state, 'slots.duration', 60);
    const duration = get(state, 'slots.duration', 60);
    const dayStart = get(state, 'slots.day_start', 0);
    const dayEnd = get(state, 'slots.day_end', 24);
    const slotes = get(state, 'slots.slots', {});
    const is_unaccompanied = get(state, 'slots.is_unaccompanied', ``);
    const office_id = get(state, 'slots.office_id', ``);
    const minDate = subDays(new Date(), -1)
    const maxDate = addDays(new Date(), 14)

    const updateSlots = (updateDate) => {
        const dateString = getDateFormat(updateDate);
        let daySlots = slotes[dateString] || []
        if (daySlots.length > 0) {
            let availableSlots = [];
            daySlots.map((item, key) => {
                let startTime = item.start_time.split(':');
                availableSlots.push(setHours(setMinutes(new Date(), startTime[1]), startTime[0]));
            });
            return availableSlots
        } else {
            return []
        }
    }

    // @todo: Need to play with start and end slot and load slot dynamic based on date select
    const onSelectFun = (data) => {
        const caleDate = new Date(data);
        const caleDateDay = caleDate.getDate();
        setSlots(updateSlots(caleDate))
    }

    const handleCalendarOpen = () => {
        setSlots(updateSlots(startDate || minDate))
        if (!startDate)
            setStartDate(minDate)
    }

    const dayClassNameFun = (day) => {
        const dateString = getDateFormat(day);
        let daySlots = slotes[dateString] || []
         return (daySlots.length) ? "has-slots" : "no-slots react-datepicker__day--disabled";
    }

    const timeClassNameFun = (time) => {
        return (time.getHours() >= dayStart &&  time.getHours() <= dayEnd) ? "allowed-slots" : "unallowed-slots";
    }

    const updateSlotDetailsFun = (updatedDate, updateStartDate=false) => {
        const dateString = getDateFormat(updatedDate);
        let daySlots = slotes[dateString] || []
        let selectedTime = getTime(updatedDate);
        if (daySlots && daySlots.length) {
            const matchingSlot  = daySlots.filter((x) => {
                return (x.start_time.toString() === selectedTime)
            })

            if (matchingSlot.length > 0) {
                if (updateStartDate)
                    setStartDate(updatedDate)
                let selectedSlot = get(matchingSlot, '0', {})
                setSelectedItems({
                    negotiator_id: selectedSlot.negotiator_id,
                    start_time: selectedSlot.start_time,
                    end_time: selectedSlot.end_time
                })
            } else {
                if (updateStartDate && daySlots.length > 0) {
                    let firstSlot = get(daySlots, '0', {});
                    let firstSlotDateTime = get(firstSlot, 'start_time', '00:00').split(':');
                    let updateDateWithSlot = new Date(new Date(updatedDate).setHours(firstSlotDateTime[0], firstSlotDateTime[1], 0))
                    setStartDate(updateDateWithSlot)
                    setSelectedItems({
                        negotiator_id: firstSlot.negotiator_id,
                        start_time: firstSlot.start_time,
                        end_time: firstSlot.end_time
                    })
                }
            }

        }
    }

    const onChangeFun = (date) => {
        updateSlotDetailsFun(date, true)
        // setStartDate(date)
    }

    const handleUnSuitableTime = (event) => {
        event.preventDefault();
        formServices.changeFormState({step:'actionCustomSlot', introMessage:''})
    }

    if (props?.defaultValue && !selectedItems?.start_time) {
        updateSlotDetailsFun(props.defaultValue)
    }

    if (!startDate && !isEmpty(slotes)) {
        let firstAvailableDate = ''
        let slotKeys = Object.keys(slotes);
        for (var i = 0; i < slotKeys.length; i++) {
            if (!isEmpty(slotes[slotKeys[i]])) {
                firstAvailableDate = slotKeys[i];
                break;
            }
        }
        if (firstAvailableDate) {
            let dateToSelect = new Date(firstAvailableDate)
            updateSlotDetailsFun(dateToSelect, true)
            setSlots(updateSlots(dateToSelect))
        }
    }

    return (
        <div className="myaccount-date-time-calendar">
            <DatePicker
                {...props}
                required
                selected={startDate}
                onChange={onChangeFun}
                showTimeSelect
                timeIntervals={timeIntervals}
                includeTimes={slots}
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                customInput={<CustomPhoneNumber />}
                minDate={minDate}
                onSelect={onSelectFun}
                shouldCloseOnSelect={false}
                maxDate={maxDate}
                minTime={setHours(setMinutes(new Date(), 0), dayStart)}
                maxTime={setHours(setMinutes(new Date(), 0), dayEnd)}
                // withPortal={true}
                calendarClassName={`myaccount-calendar`}
                dayClassName={dayClassNameFun}
                timeClassName={timeClassNameFun}
                placeholderText="Click to select slot"
                onCalendarOpen={handleCalendarOpen}
                inline
            >
                {showBtnSuitableTime && (
                    <div className={`btn-suitable-time`}>
                        <Button classes={{root: "globalForm-formButton"}} onClick={(event) => handleUnSuitableTime(event) }>
                            I can't find a suitable time
                        </Button>
                    </div>
                )}
            </DatePicker>
            <div style={{display: 'none'}}>
                <TextField name="office_id" type="hidden" value={office_id} />
                <TextField name="appointment_time" type="hidden" value={datePickerFormat(startDate)} />
                <TextField name="negotiator_id" type="hidden" value={selectedItems?.negotiator_id} />
                <TextField name="is_unaccompanied" type="hidden" value={is_unaccompanied} />
                <TextField name="start_time" type="hidden" value={selectedItems?.start_time} />
                <TextField name="end_time" type="hidden" value={selectedItems?.end_time} />
                <TextField name="duration" type="hidden" value={duration} />
            </div>
        </div>
    )
}

const FormDateFieldWrap = (props) => {
    return (
        <FormOptionStoreProvider>
            <FormDateField {...props} />
        </FormOptionStoreProvider>
    )
}
export default React.memo(FormDateFieldWrap)
