/**
Ref: https://medium.com/front-end-weekly/html5-form-validation-in-react-65712f778196
     https://time2hack.com/form-with-react-html5-validations/
     https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

Note: default props inputRef, onChange, error HelperText can be overridden by passing it in props
*/

import React, { useState, useEffect } from "react"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormOptionStoreProvider, useFormOptionState } from "@myaccountServices";
import { Field } from "formik";

const FormSelectField = (props) => {
    const {
        id,
        values,
        defaultValue,
        defaultKey,
        reference,
        basedOn,
        basedOnRef,
        funcForValueFunc,
        funcForValue,
        onChange,
        setBasedOnRef,
        name,
        placeholder,
        label,
        required,
        error,
        validationBasedOn,
        validationBasedOnRef,
        setValidationBasedOnRef,
        ...rest
    } = props

    const { state, services } = useFormOptionState();
    const [value, setValue] = useState(defaultValue || "")
    const [basedOnValue, setBasedOnValue] = useState(defaultKey || '')
    let optionsList = values;
    if (state?.options) {
        if (state.options.length)
            optionsList = state.options;
    }
    const [options, setOptions] = useState(optionsList)

    useEffect(() => {
        if (props?.optionFromApiCall && props?.configType) {
            if (!(state?.options)) {
                services.getConfigValues({type:props.configType})
            } else {
                let emptyOption = props?.emptyOption || []
                if (state.options.length)
                    setOptions(emptyOption.concat(optionsList))
            }
        }
    }, [values, state?.options])

    useEffect(() => {
        if ((reference || basedOn) && basedOnRef[basedOn]) {
            setBasedOnValue(basedOnRef[basedOn])
            if (reference)
                setValue(basedOnRef[basedOn])
        }
    }, [basedOnRef])

    const handleChange = event => {
        setValue(event.target.value)
        // handle funcForValueFunc params
        if ( funcForValueFunc && (funcForValue === event.target.value) ) {
            funcForValueFunc();
        // handle custom onChange function
        } else if ( onChange ) {
            onChange(event);
        }
        if (reference) {
            setBasedOnRef({[name]: event.target.value})
        }
        
        if(validationBasedOn) {
            setValidationBasedOnRef({[name]: event.target.value})
        }
    }

    return (
        <>
            <FormControl
                className="globalForm-selectField-wrap"
                required={required}
                error={error}
            >
                <InputLabel
                    id={`select-label-${id}`}
                >
                    {label}
                </InputLabel>
                <Select
                    name={name}
                    value={value}
                    labelId={`select-label-${id}`}
                    id={`select-field-${id}`}
                    label={label}
                    onChange={handleChange}
                    {...rest}
                >
                { (Array.isArray(options))
                    ? options.map(item => {
                        let optionValue = item
                        let optionLabel = item
                        if (typeof item === "object") {
                            Object.keys(item).forEach(key => {
                                optionValue = key
                                optionLabel = item[key]
                            })
                        }

                        return <MenuItem key={optionValue} value={optionValue}>{optionLabel}</MenuItem>
                    })
                    : options[basedOnValue]?.map(item => {
                        let optionValue = item
                        let optionLabel = item
                        if (typeof item === "object") {
                            Object.keys(item).forEach(key => {
                                optionValue = key
                                optionLabel = item[key]
                            })
                        }

                        let disabled = false;
                        if(validationBasedOn && validationBasedOnRef && validationBasedOnRef?.[validationBasedOn]) {
                            if(name === 'maxPrice' && optionValue !== '' && optionValue <= parseInt(validationBasedOnRef[validationBasedOn])) {
                                disabled = true;
                            } else if(name === 'minPrice' && optionValue !== '' && optionValue >= parseInt(validationBasedOnRef[validationBasedOn])) {
                                disabled = true;
                            }
                        }

                        return <MenuItem key={optionValue} value={optionValue} disabled={disabled}>{optionLabel}</MenuItem>
                    })
                }
                </Select>
            </FormControl>
        </>
    )
}

const FormSelectFieldWrap = (props) => {
    return (
        <FormOptionStoreProvider>
            <FormSelectField {...props} />
        </FormOptionStoreProvider>
    )
}
export default FormSelectFieldWrap
